<template>
  <div class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Partner
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <!-- eslint-disable -->
        <Information
          :email="email"
          :fullname="fullname"
          :phone="phone"
          :username="username"
          :gender="gender"
          :nik="nik"
          :subdistrictId="subdistrictId"
          :address="address"
          :mou="mou"
          :listSubdistrict="listSubdistrict"
          :imageFile="imageFile"
          :imageInitialFile="imageInitialFile"

          @update:email="val => email = val"
          @update:fullname="val => fullname = val"
          @update:phone="val => phone = val"
          @update:username="val => username = val"
          @update:gender="val => gender = val"
          @update:nik="val => nik = val"
          @update:subdistrictId="val => subdistrictId = val"
          @update:address="val => address = val"
          @update:mou="val => mou = val"
          @search:subdistrictId="val => onSearchSubdistrict(val)"
          @update:imageFile="val => imageFile = val"
        />

        <Contact
          :phoneBusiness="phoneBusiness"
          :businessName="businessName"
          :picName="picName"
          :picPhone="picPhone"

          @update:phoneBusiness="val => phoneBusiness = val"
          @update:businessName="val => businessName = val"
          @update:picName="val => picName = val"
          @update:picPhone="val => picPhone = val"
        />

        <BankAccounts
          :bankAccounts="bankAccounts"
          :listBank="listBank"

          @update:bank_owner_name="val => bankAccounts[val.index].bank_owner_name = val.value"
          @update:bank_owner_no="val => bankAccounts[val.index].bank_owner_no = val.value"
          @update:bankCode="val => val.value ? (bankAccounts[val.index].bank_code = val.value.code, bankAccounts[val.index].bank_name = val.value.name) : (bankAccounts[val.index].bank_code = '', bankAccounts[val.index].bank_name = '')"
        />

        <DataKomerce
          :listSectorbusiness="listSectorbusiness"
          :listTypeBussiness="listTypeBussiness"

          :joinDate="joinDate"
          :businessSectorId="businessSectorId"
          :businessTypeId="businessTypeId"
          :brandName="brandName"
          :teamMembers="teamMembers"
          :reference="reference"
          :bonus="bonus"

          @update:joinDate="val => joinDate = val"
          @update:businessSectorId="val => businessSectorId = val"
          @update:businessTypeId="val => businessTypeId = val"
          @update:brandName="val => brandName = val"
          @update:teamMembers="val => teamMembers = val"
          @update:reference="val => reference = val"
          @update:bonus="val => bonus = val"
        />

        <Password
          :password="password"
          :confirmationPassword="confirmationPassword"

          @update:password="val => password = val"
          @update:confirmationPassword="val => confirmationPassword = val"
        />

        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalConfirmation />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  required,
  min,
  minValue,
  max,
  alpha_spaces,
  numeric,
} from '@validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { capitalize } from '@/libs/helpers'
import ModalConfirmation from './ModalConfirmation.vue'
import BankAccounts from './form-group/BankAccounts.vue'
import Contact from './form-group/Contact.vue'
import Password from './form-group/Password.vue'
import DataKomerce from './form-group/DataKomerce.vue'
import Information from './form-group/Information.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationObserver,
    ModalConfirmation,
    BankAccounts,
    Contact,
    Password,
    DataKomerce,
    Information,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      capitalize,

      required,
      min,
      max,
      minValue,
      alpha_spaces,
      numeric,

      fullname: '',
      username: '',
      gender: '',
      nik: '',

      subdistrictId: null,
      address: '',
      mou: '',

      phone: '',
      email: '',
      picName: '',
      picPhone: '',

      phoneBusiness: '',
      businessName: '',

      bankCode: '',
      bank_owner_no: '',
      bank_owner_name: '',

      bankAccounts: [
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
        {
          bank_code: '',
          bank_name: '',
          bank_owner_no: '',
          bank_owner_name: '',
        },
      ],

      joinDate: null,
      businessSectorId: null,
      businessTypeId: null,
      brandName: '',
      teamMembers: '',
      reference: '',
      bonus: '',

      password: '',
      confirmationPassword: '',

      subdistrictKeyword: '',

      imageFile: null,
      imageInitialFile: null,
      banks: [],

      listSubdistrict: [],
      listBank: [],
      listSectorbusiness: [],
      listTypeBussiness: [],
      detailPartner: {},

      alertError,
      alertSuccess,
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  async mounted() {
    this.getDetailPartner()
    this.getListBank()
    this.getListSectorBisnis()
    this.getListTypeBisnis()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('email', this.email)
      formData.append('full_name', this.fullname)
      formData.append('phone_number', this.phone)
      formData.append('username', this.username)
      formData.append('gender', this.gender)
      formData.append('nik', this.nik)
      formData.append('subdistrict_id', (!Number.isInteger(this.subdistrictId)) ? this.detailPartner.subdistrict_id : this.subdistrictId)
      formData.append('address', this.address)
      formData.append('mou', this.mou)

      formData.append('pic_name', this.businessName)
      formData.append('pic_phone_number', this.phoneBusiness)
      formData.append('komtim_pic_name', this.picName)
      formData.append('komtim_pic_phone_number', this.picPhone)

      formData.append('join_date', this.joinDate)
      formData.append('business_sector_id', Number.isInteger(this.businessSectorId) ? this.businessSectorId : this.detailPartner.business_sector_id)
      formData.append('business_type_id', Number.isInteger(this.businessTypeId) ? this.businessTypeId : this.detailPartner.business_type_id)
      formData.append('brand_name', this.brandName)
      formData.append('team_members', this.teamMembers)
      formData.append('reference', this.reference)
      formData.append('bonus', this.bonus)

      if (this.confirmationPassword !== '') formData.append('confirmation_password', this.confirmationPassword)
      if (this.password !== '') formData.append('password', this.password)

      if (this.imageFile) formData.append('photo_profile', this.imageFile)

      const temp = this.bankAccounts.slice((this.detailPartner.bank_accounts && this.detailPartner.bank_accounts.length) ? this.detailPartner.bank_accounts.length : 0)
      const filtered = temp.filter(e => e.bank_owner_no !== '')

      const url = `v1/partners/${this.$route.params.id}/update`
      await komtimAxiosIns
        .put(url, formData)
        .then(() => {
          const text = 'Berhasil mengubah data'

          if (filtered.length) this.submitBankAccounts(filtered)
          else {
            this.alertSuccess(text)
            this.$router.push({ name: 'partners' })
          }
        })
        .catch(error => {
          this.loadingSubmit = false

          if (error.response.status === 500) this.$toast_error({ message: 'Internal Server error' })
          else this.alertError(error)
        })
    },
    async getDetailPartner() {
      const url = `v1/partners/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailPartner = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListBank() {
      this.offset = 0
      const url = 'v1/bank/available-bank'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listBank = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSectorBisnis() {
      this.offset = 0
      const url = 'v1/business_sectors/resource?status=true'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSectorbusiness = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListTypeBisnis() {
      this.offset = 0
      const url = 'v1/business_types/resource?status=true'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listTypeBussiness = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListSubdistrict(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&keyword=${keyword}`
      const url = `v1/region/villages?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listSubdistrict = data
          this.listSubdistrict = this.listSubdistrict.map(item => {
            const result = {
              ...item,
              label: `${item.subdistrict_name}, ${item.district_name}, ${item.regency_name}, ${item.province_name}`,
            }
            return result
          })
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchSubdistrict: _.debounce((search, it) => {
      it.getListSubdistrict(search)
    }, 500),
    onSearchSubdistrict(search) {
      this.searchSubdistrict(search, this)
    },
    uploadImage(e) {
      const img = e.target.value
      const fileExtension = img.substr((img.lastIndexOf('.') + 1))
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png') {
        // eslint-disable-next-line no-alert
        alert('Hanya dapat upload file dengan ekstensi JPG, PNG dan JPEG')
        this.imageFile = ''
      } else {
        this.imageFile = img
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    loadForm() {
      this.fullname = this.detailPartner.full_name
      this.username = this.detailPartner.username
      this.gender = this.detailPartner.gender
      this.nik = this.detailPartner.nik
      this.mou = this.detailPartner.mou

      this.subdistrictId = {
        district_name: this.detailPartner.district_name,
        province_name: this.detailPartner.province_name,
        regency_name: this.detailPartner.regency_name,
        subdistrict_id: this.detailPartner.subdistrict_id,
        subdistrict_name: this.detailPartner.subdistrict_name,
        label: `${this.detailPartner.subdistrict_name}, ${this.detailPartner.district_name}, ${this.detailPartner.regency_name}, ${this.detailPartner.province_name}`,
      }
      this.address = this.detailPartner.address

      this.phone = this.detailPartner.phone_number
      this.email = this.detailPartner.email
      this.picName = this.detailPartner.komtim_pic_name
      this.picPhone = this.detailPartner.komtim_pic_phone_number
      this.businessName = this.detailPartner.pic_name
      this.phoneBusiness = this.detailPartner.pic_phone_number

      this.joinDate = this.detailPartner.join_date
      this.businessSectorId = this.detailPartner.business_sector_name
      this.businessTypeId = this.detailPartner.business_type_name
      this.brandName = this.detailPartner.brand_name
      this.teamMembers = this.detailPartner.team_members
      this.reference = this.detailPartner.reference
      this.bonus = this.detailPartner.bonus

      if (this.detailPartner.bank_accounts) {
        this.bankAccounts = this.detailPartner.bank_accounts.concat(this.bankAccounts.slice(this.detailPartner.bank_accounts.length))
      }

      if (this.detailPartner.photo_profile_url) this.imageInitialFile = this.detailPartner.photo_profile_url
    },
    async submitBankAccounts(bankAccounts) {
      const payload = {
        partner_id: this.$route.params.id,
        bank_accounts: bankAccounts,
      }

      const url = 'v1/partners/store/bank_account'
      await komtimAxiosIns
        .post(url, payload)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'partners' })
        })
        .catch(error => {
          this.alertError(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
